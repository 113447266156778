body {
    --header-height: 72px;
    --container-width: 1216px;
    --container-padding-x: var(--space-4);

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-body);
    font-size: 16px;

    position: relative;
    min-height: 100vh;
    height: 100%;
    width: 100%;
}

header {
    background-color: transparent;
    color: white;
    height: var(--header-height);
    max-height: var(--header-height);
    transition-property: box-shadow, background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 4;
}

header.is-stuck {
    background-color: var(--color-secondary-600);
    box-shadow:
        0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -2px rgba(0, 0, 0, 0.05);
    z-index: 5;
}

header > .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
    max-width: var(--container-width);
    width: 100%;
    padding: var(--space-4) var(--container-padding-x);
    position: static;
}

header .header-logo {
    height: 36px;
    overflow: visible;
    width: auto;
}

header .header-logo > img {
    height: 100%;
    width: auto;
    position: relative;
    top: 16.667%;
}

header .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;

    appearance: none;
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0;
}

header .menu-navigation {
    display: none;
    flex-direction: column;

    background-color: var(--color-secondary-600);
    padding-top: calc(var(--header-height) + var(--space-4));
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

header .menu-navigation > ul {
    display: flex;
    flex-direction: column;

    list-style: none;
}

header .menu-navigation > ul > li {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    list-style: none;
    height: 56px;
}

header .menu-navigation > ul > li > a {
    display: flex;
    align-items: center;

    color: white;
    text-decoration: none;
    padding: var(--space-2) var(--container-padding-x);
    width: 100%;
}

header .menu-navigation > .nav-countries {
    margin: 0px var(--container-padding-x);
}

header .menu-navigation > .nav-countries .dropdown-toggle > fast-button {
    font-weight: 400;
    --padding-left: 0px;
}

header .menu-navigation > .nav-countries .dropdown-toggle > fast-button {
    --background-hover: transparent;
    --border-hover: transparent;
    --outline: transparent;
}

header .menu-navigation > .nav-countries fast-icon[type="flag"] {
    --height: 24px;
    --width: 24px;
}

header .menu-navigation > .nav-countries ul[slot='content'] > li > a {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: var(--space-3);

    color: var(--color-black);
    text-decoration: none;
    padding: var(--space-4);
}

header
    .header-menu
    > .menu-navigation
    > .nav-countries
    ul[slot='content']
    > li
    > a:hover {
    background-color: var(--color-neutral-100);
}

header .menu-navigation > .nav-actions {
    margin-bottom: 0px;
    margin-top: auto;
    padding: var(--space-2) var(--container-padding-x);
}

header .menu-navigation > .nav-actions > li > fast-button {
    width: 100%;
}

header.is-menu-open .header-logo {
    position: relative;
    z-index: 5;
}

header.is-menu-open .header-menu .menu-button {
    position: relative;
    z-index: 5;
}

header.is-menu-open .header-menu > .menu-navigation {
    display: flex;
    z-index: 4;
}

main {
    color: var(--color-black);
    font-family: var(--font-body);
    flex-grow: 1;
    height: auto;
    width: 100%;
}

main > section:first-child {
    background-color: var(--color-secondary-600);
    color: var(--color-white);
    padding-top: calc(var(--header-height) + var(--padding-top));
}

main > section {
    border: none;
}

main > section h2 {
    color: var(--color-secondary-600);
    font-family: var(--font-heading);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

main > section h2 + p {
    color: var(--color-neutral-700);
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-top: var(--space-4);
    text-align: center;
}

main > section > .section-container,
footer > .footer-container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--container-width);
    padding: var(--padding-top, var(--padding-y, 0px))
        var(--padding-right, var(--padding-x, var(--container-padding-x)))
        var(--padding-bottom, var(--padding-y, 0px))
        var(--padding-left, var(--padding-x, var(--container-padding-x)));
}

footer {
    background-color: var(--color-secondary-800);
    color: var(--color-white);
    width: 100%;
}

footer > .footer-container {
    display: grid;
    gap: var(--space-10);
    --padding-y: var(--space-10);
}

footer .footer-logo {
    display: block;
    height: 44px;
    margin-bottom: var(--space-8);
}

footer .footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-10);
}

footer .footer-end {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: grid;
    justify-content: center;
    gap: var(--space-10);
    padding: var(--space-10) 0px var(--space-6);
}

footer .link-group {
    display: flex;
    flex-direction: column;
    font-family: var(--font-body);
    font-size: 16px;
    line-height: 24px;
    list-style: none;
    gap: var(--space-4);
}

footer .link-group > h4 {
    color: var(--color-secondary-200);
    font-weight: 700;
    text-transform: uppercase;
}

footer .link-group > li > a {
    color: inherit;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}

footer .link-group > li > a:hover {
    color: var(--color-primary-400);
}

footer .link-contacts > li > a {
    display: flex;
    align-items: center;
    gap: var(--space-2);
}

footer .link-apps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-2);
}

footer .link-apps > a {
    height: 48px;
}

footer .link-apps > a > img {
    width: auto;
    height: 100%;
}

footer .link-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-2);
}

footer .link-socials > a {
    color: inherit;
    padding: var(--space-2);
    height: 48px;
    width: 48px;
}

footer .copyright {
    color: var(--color-neutral-400);
    font-size: 12px;
    line-height: 16px;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
}

#companies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-6);
    padding: var(--space-14) 0px var(--space-10);
    overflow: hidden;
}

#companies > h2 {
    font-family: var(--font-body);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

#companies-scroller {
    --gap: var(--space-11);

    display: flex;
    margin: 0px;
    width: 100%;
    gap: var(--gap);
}

#companies-scroller > .scroller-track {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-shrink: 0;
    gap: var(--gap);
    list-style: none;
    margin: auto;
}

#companies-scroller.is-scrolling > .scroller-track {
    animation: scroll 120s linear infinite;
}

#companies-scroller > .scroller-track > .scroller-item {
    flex: 0 0 auto;
    height: 40px;
}

#companies-scroller > .scroller-track > .scroller-item > img {
    filter: grayscale(1);
    height: 100%;
    width: auto;
}

.toast {
    max-width: 380px;
    color: #000000;
    background-color: #fff;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: absolute;
    z-index: 1000;
    right: 20px;
    top: 100px;
    font-size: 14px;
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.toast .toast-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.toast .line-vertical {
    width: 4px;
    height: 50px;
    background-color: #B91C1C;
    border-radius: 10px;
}

.toast .line-vertical-success {
    width: 4px;
    height: 50px;
    background-color: #11b981;
    border-radius: 10px;
}

.toast .toast-content {
    text-align: left;
}

.toast.show-toast {
    display: flex;
}

a.resend-activation.disabled {
    pointer-events: none; 
    opacity: 0.6;
    cursor: not-allowed;
}

/** TABLET (MD) **/

@media (min-width: 768px) {
    body {
        --container-padding-x: var(--space-8);
    }

    main > section h2 {
        font-size: 36px;
        line-height: 40px;
    }

    footer > .footer-container {
        gap: var(--space-12);
        --padding-bottom: var(--space-6);
        --padding-top: var(--space-20);
    }

    footer .footer-end {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding-bottom: var(--space-10);
    }

    footer .link-apps {
        flex-direction: row;
        justify-content: center;
        grid-column: 1 / -1;
        order: 1;
    }

    footer .link-socials {
        justify-content: flex-end;
        order: 3;
    }

    footer .copyright {
        order: 2;
    }
}

/** DESKTOP (LG) **/

@media (min-width: 1024px) {
    header > .header-container {
        gap: var(--space-4);
    }

    header .header-menu {
        flex-grow: 1;
    }

    header .menu-button {
        display: none;
    }

    header .menu-navigation {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: var(--space-4);

        background-color: transparent;
        padding: 0px;
        position: static;
        transform: none !important;
    }

    header .menu-navigation > ul > li {
        height: auto;
    }

    header .menu-navigation > ul > li > a {
        padding: 0px var(--space-2);
    }

    header .menu-navigation > .nav-countries {
        margin: 0px;
        order: 1;
    }

    header .menu-navigation > .nav-countries .dropdown-toggle > fast-button {
        --padding-left: var(--space-4);
        --padding-right: var(--space-4);
    }

    header .menu-navigation > .nav-countries .dropdown-toggle > fast-button {
        --background-hover: var(--button-color-darker);
        --border-hover: var(--button-color-darker);
        --outline: var(--color-outline);
    }

    header .menu-navigation > .nav-links {
        flex-direction: row;
        gap: var(--space-4);
        order: 2;
    }

    header .menu-navigation > .nav-links > li {
        height: 40px;
        position: relative;
    }

    header .menu-navigation > .nav-links > li::after {
        content: '';
        background-color: var(--color-white);
        opacity: 0;
        transition: all 0.3s ease-out;
        position: absolute;
        height: 2px;
        width: 0%;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }

    header .menu-navigation > .nav-links > li:hover::after {
        opacity: 1;
        width: 100%;
    }

    header .menu-navigation > .nav-actions {
        flex-direction: row-reverse;
        gap: var(--space-3);
        margin-right: 0px;
        margin-left: auto;
        padding: 0px;
        order: 3;
    }

    header .menu-navigation > .nav-actions > li > fast-button {
        height: 40px;
        width: auto;
        --padding-x: var(--space-6);
    }

    main > section#hero {
        --padding-top: var(--space-10);
    }

    main > section h2 {
        font-size: 48px;
        line-height: 64px;
    }

    footer > .footer-container {
        grid-template-columns: repeat(3, 1fr);
        gap: var(--space-16);
    }

    footer .footer-links {
        display: flex;
        flex-direction: column;
        gap: var(--space-10);
    }

    footer .footer-end {
        grid-column: 1 / -1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    footer .link-apps {
        grid-column: auto;
        order: 2;
    }

    footer .link-socials {
        order: 3;
    }

    footer .copyright {
        order: 1;
    }
}

@keyframes fade-in-left {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in-right {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.animate-fade-in-right {
    animation: fade-in-right 1s ease-out forwards;
}

.animate-fade-in-left {
    animation: fade-in-left 1s ease-out forwards;
}

@keyframes fade-in-down {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-up {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fade-in-down {
    animation: fade-in-down 1s ease-out forwards;
}

.animate-fade-in-up {
    animation: fade-in-up 1s ease-out forwards;
}

